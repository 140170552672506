@import './variables';

// Import BS.
@import '../../node_modules/bootstrap/scss/bootstrap';

.trans-bg {
  background-color: $ge-partial-trans-white;
}

.dot {
  height: 1.25rem;
  width: 1.25rem;
  background-color: $primary;
  border-radius: 50%;
  display: inline-block;
}

.image-placeholder {
  background-color: $gray-600;
}

.color-white {
  color: $white;
}

.heroicon {
  width: 1.5rem;
  height: 1.5rem;
}

.heroicon-lg {
  width: 2rem;
  height: 2rem;
}

ul.no-decoration {
  list-style: none;
}

header .logo {
  width: 273px;
  height: 100px;
  background-image: url('https://d21c62yfvjssc.cloudfront.net/assets/gruetzent-website/logo.png');
  background-size: cover;
  background-repeat: no-repeat;
}

.section-welcome {
  height: $ge-banner-height;
  background-image: url('https://d21c62yfvjssc.cloudfront.net/assets/gruetzent-website/banner-background-1.jpg');
  background-size: cover;
  background-position: center center;
}

.section-services {
  .card {
    border: none;
    border-radius: 0;
    border-top: 0.5rem solid $primary;
  }
}

.section-banner {
  height: $ge-banner-height;
  background-image: url('https://d21c62yfvjssc.cloudfront.net/assets/gruetzent-website/banner-background-2.jpg');
  background-size: cover;
  background-position: center center;

  .section-text {
    color: $white;
    filter: none !important;
  }
}

.section-gallery {
  .gallery-thumb {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 12.5rem;
    height: 12.5rem;
    margin: 0.5rem;
    cursor: pointer;

    @include media-breakpoint-down(sm) {
      width: 6.25rem;
      height: 6.25rem;
    }
  }
}

.section-service-area {
  background-color: $ge-backdrop-black;
  color: $white;
}

footer {
  min-height: 10rem;

  .container {
    border-top: 0.1rem solid lightgray;
  }
}
